import * as React from "react"
import { Link } from "gatsby"
import { 
   Heading,
   Text,
} from "@chakra-ui/react"
import Layout from '../components/layout.js';

const NotFoundPage = (pageprops) => {
   return (
      <Layout uri={pageprops.uri}>
         <Heading>Not found</Heading>
         <Heading size="xl">Page not found</Heading>
         <Text>
            Sorry{" "}
            we couldn’t find what you were looking for.
            <Link to="/">Go home</Link>.
         </Text>
      </Layout>
   )
}

export default NotFoundPage
